import { InjectionToken, Provider, inject } from '@angular/core';

import { AuthenticationFacadeService } from '@finverity/authentication';
import { CompanyType } from '@finverity/graphql';
import { IDENTITY_ACCESS_TOKEN } from '@finverity/identity-access';

import {
  NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_ADMINS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_CUSTOMERS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_FUNDERS,
} from '../constants';
import { NavigationMenuPermissions } from '../identity-access';
import {
  NavigationSidebarMenuBottomSectionItem,
  NavigationSidebarMenuGroup,
  NavigationSidebarMenuItem,
  NavigationSidebarMenuItemBase,
  NavigationSidebarMenuSection,
  NavigationSidebarMenuSectionIdentifier,
} from '../types';

function isMenuItemCompatibleWithAuthUserCompanyType(
  menuItem: NavigationSidebarMenuItemBase,
  authCompanyType: CompanyType
): boolean {
  return !menuItem.companyTypes || menuItem.companyTypes.includes(authCompanyType);
}

function filterSectionsByCompanyTypeAndPermissions(
  sections: NavigationSidebarMenuSection[],
  permissionsMap: Record<NavigationMenuPermissions, boolean>,
  companyType: CompanyType
): NavigationSidebarMenuSection[] {
  const filteredSections: NavigationSidebarMenuSection[] = [];

  for (const section of sections) {
    const filteredMenuItems: Array<NavigationSidebarMenuGroup | NavigationSidebarMenuItem> = [];

    for (const item of section.menuItems) {
      if (!isMenuItemCompatibleWithAuthUserCompanyType(item, companyType)) {
        continue;
      }

      if (!permissionsMap[item.permission as NavigationMenuPermissions]) {
        continue;
      }

      if ((item as NavigationSidebarMenuGroup).subMenuItems) {
        const group = item as NavigationSidebarMenuGroup;
        const filteredSubItems = group.subMenuItems.filter(subItem => {
          const matchesCompanyType = isMenuItemCompatibleWithAuthUserCompanyType(subItem, companyType);
          const hasPermission = permissionsMap[subItem.permission as NavigationMenuPermissions];
          return matchesCompanyType && hasPermission;
        });

        if (filteredSubItems.length > 0) {
          filteredMenuItems.push({ ...group, subMenuItems: filteredSubItems });
        }
      } else {
        filteredMenuItems.push(item);
      }
    }

    if (filteredMenuItems.length > 0) {
      filteredSections.push({ ...section, menuItems: filteredMenuItems });
    }
  }

  return filteredSections;
}

function sortSectionsByAuthUserCompanyType(
  sections: NavigationSidebarMenuSection[],
  ordering: NavigationSidebarMenuSectionIdentifier[]
): NavigationSidebarMenuSection[] {
  return [...sections].sort((section1, section2) => {
    const section1Index = ordering.indexOf(section1.sectionIdentifier);
    const section2Index = ordering.indexOf(section2.sectionIdentifier);
    return section1Index - section2Index;
  });
}

function navigationSidebarMenuSectionsFactory(): NavigationSidebarMenuSection[] {
  const authUser = inject(AuthenticationFacadeService).userSignal();
  const permissionsMap = inject<Record<NavigationMenuPermissions, boolean>>(IDENTITY_ACCESS_TOKEN);

  const filteredSections: NavigationSidebarMenuSection[] = filterSectionsByCompanyTypeAndPermissions(
    NAVIGATION_SIDEBAR_MENU_SECTIONS,
    permissionsMap,
    authUser?.company_type as CompanyType
  );

  let sortedSections: NavigationSidebarMenuSection[] = filteredSections;
  if (authUser?.company_type === CompanyType.Customer) {
    sortedSections = sortSectionsByAuthUserCompanyType(
      filteredSections,
      NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_CUSTOMERS
    );
  } else if (authUser?.company_type === CompanyType.Funder) {
    sortedSections = sortSectionsByAuthUserCompanyType(
      filteredSections,
      NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_FUNDERS
    );
  } else if (authUser?.company_type === CompanyType.PortalAdmin) {
    sortedSections = sortSectionsByAuthUserCompanyType(
      filteredSections,
      NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_ADMINS
    );
  }

  return sortedSections;
}

export const NAVIGATION_SIDEBAR_MENU_SECTIONS_TOKEN = new InjectionToken<NavigationSidebarMenuSection[]>(
  '[NAVIGATION_SIDEBAR_MENU_SECTIONS]'
);

export const NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS_TOKEN = new InjectionToken<
  NavigationSidebarMenuBottomSectionItem[]
>('[NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS]');

export function provideNavigationMenuItems(): Provider[] {
  return [
    {
      provide: NAVIGATION_SIDEBAR_MENU_SECTIONS_TOKEN,
      useFactory: navigationSidebarMenuSectionsFactory,
    },
    {
      provide: NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS_TOKEN,
      useValue: NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS,
    },
  ];
}

export enum HeaderNavigationMenuItemIdentifier {
  ProfileSettings = 'PROFILE_SETTINGS',
  NotificationSettings = 'NOTIFICATION_SETTINGS',
}

export interface HeaderNavigationMenuItemBase {
  readonly identifier: HeaderNavigationMenuItemIdentifier;
  readonly title: string;
  readonly disabled: boolean;
  // add for case from https://finverity.atlassian.net/browse/FDS-988 story
  // WHEN the user clicks on the Company Notifications menu in the sidebar
  // THEN redirect the user to the Company Notification Settings page (/company-notifications)
  readonly titleForHeader?: string;
  //
  readonly iconName?: string;
}

export interface HeaderNavigationMenuItem extends HeaderNavigationMenuItemBase {
  readonly route: string;
}

import { InjectionToken, Provider } from '@angular/core';

import { HEADER_NAVIGATION_MENU_ITEMS } from '../constants';
import { HeaderNavigationMenuItem } from '../types';

export const HEADER_NAVIGATION_MENU_ITEMS_TOKEN = new InjectionToken<HeaderNavigationMenuItem[]>(
  '[HEADER_NAVIGATION_MENU_ITEMS]'
);

export function provideHeaderNavigationMenuItems(): Provider[] {
  return [
    {
      provide: HEADER_NAVIGATION_MENU_ITEMS_TOKEN,
      useValue: HEADER_NAVIGATION_MENU_ITEMS,
    },
  ];
}

import { inject } from '@angular/core';

import { AuthenticationFacadeService } from '@finverity/authentication';
import { CompanyType } from '@finverity/graphql';
import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { NavigationMenuPermissions } from './navigation-menu-permissions';

const NAVIGATION_MENU_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<NavigationMenuPermissions> = (
  authService: AuthService,
  policyService: PolicyService
) => {
  const authFacadeService = inject(AuthenticationFacadeService);

  return {
    [NavigationMenuPermissions.ViewDashboard]: true, // policyService.hasPolicies([PolicyType.VIEW_DASHBOARD]), // TODO: add policies here when they are defined in the backend
    [NavigationMenuPermissions.ViewCompanies]: authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin, // policyService.hasPolicies([PolicyType.VIEW_COMPANIES]), // TODO: add policies here when they are defined in the backend
    [NavigationMenuPermissions.ViewSelfOnboarding]:
      policyService.hasPolicies(PolicyType.VIEW_CAMPAIGNS) &&
      policyService.hasPolicies(PolicyType.VIEW_LANDING_PAGES) &&
      policyService.hasPolicies(PolicyType.VIEW_BUNDLES) &&
      authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin,

    [NavigationMenuPermissions.ViewProducts]:
      policyService.hasPolicies(PolicyType.VIEW_PRODUCT_GROUPS) &&
      policyService.hasPolicies(PolicyType.VIEW_PRODUCT_STRUCTURES) &&
      policyService.hasPolicies(PolicyType.VIEW_FLOATING_RATE_TEMPLATES_AND_RATES) &&
      policyService.hasPolicies(PolicyType.VIEW_FX_RATE_TEMPLATES_AND_PAIRS) &&
      authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin,
    [NavigationMenuPermissions.ViewProductGroups]: policyService.hasPolicies(PolicyType.VIEW_PRODUCT_GROUPS),
    [NavigationMenuPermissions.ViewProductStructures]: policyService.hasPolicies(PolicyType.VIEW_PRODUCT_STRUCTURES),
    [NavigationMenuPermissions.ViewFloatingRates]: policyService.hasPolicies(
      PolicyType.VIEW_FLOATING_RATE_TEMPLATES_AND_RATES
    ),
    [NavigationMenuPermissions.ViewFXRates]: policyService.hasPolicies(PolicyType.VIEW_FX_RATE_TEMPLATES_AND_PAIRS),

    [NavigationMenuPermissions.ViewProgrammesAndDeals]:
      policyService.hasPolicies(PolicyType.VIEW_PROGRAMMES) &&
      policyService.hasPolicies(PolicyType.VIEW_ALL_DEALS) &&
      authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin,
    [NavigationMenuPermissions.ViewProgrammes]: policyService.hasPolicies(PolicyType.VIEW_PROGRAMMES),
    [NavigationMenuPermissions.ViewDeals]: policyService.hasPolicies(PolicyType.VIEW_ALL_DEALS),
    [NavigationMenuPermissions.ViewInstrumentUpload]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [NavigationMenuPermissions.ViewUserManagement]:
      (policyService.hasPolicies(PolicyType.VIEW_INTERNAL_USER_ROLES) &&
        policyService.hasPolicies(PolicyType.VIEW_INTERNAL_USERS)) ||
      policyService.hasPolicies(PolicyType.CONFIGURE_MANDATORY_2FA_ON_LOGIN),
    [NavigationMenuPermissions.ViewAuditTrail]:
      policyService.hasPolicies(PolicyType.VIEW_AUDIT_TRAIL) &&
      authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin,
    [NavigationMenuPermissions.ViewCompanyNotifications]:
      policyService.hasPolicies(PolicyType.VIEW_INTERNAL_USER_ROLES) &&
      policyService.hasPolicies(PolicyType.VIEW_ROLE_NOTIFICATION_PROPERTIES),

    [NavigationMenuPermissions.ViewNotifications]: policyService.hasPolicies(PolicyType.VIEW_NOTIFICATIONS),

    [NavigationMenuPermissions.InstrumentApproval]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [NavigationMenuPermissions.ApproveRejectInstrumentsPayerApprovalMaker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_PAYER_APPROVAL_MAKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_PAYER_APPROVAL_MAKER),
    [NavigationMenuPermissions.ApproveRejectInstrumentsPayerApprovalChecker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_PAYER_APPROVAL_CHECKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_PAYER_APPROVAL_CHECKER),
    [NavigationMenuPermissions.ManageRejectedInstrumentsPayerApproval]:
      policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS) &&
      policyService.hasPolicies(PolicyType.UNDO_REJECTION_PAYER_APPROVAL),
    [NavigationMenuPermissions.MarkAsPaidWithoutFinancing]: policyService.hasPolicies(
      PolicyType.MARK_INSTRUMENTS_AS_PAID
    ),

    [NavigationMenuPermissions.ViewDisbursements]: policyService.hasPolicies(PolicyType.VIEW_DISBURSEMENT_FILES),
    [NavigationMenuPermissions.ViewRepayments]: policyService.hasPolicies(PolicyType.VIEW_REPAYMENTS),

    [NavigationMenuPermissions.FinanceRequest]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [NavigationMenuPermissions.ApproveRejectInstrumentsSellerFinanceRequestMaker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_SELLER_FINANCE_REQUEST_MAKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_SELLER_FINANCE_REQUEST_MAKER),
    [NavigationMenuPermissions.ApproveRejectInstrumentsSellerFinanceRequestChecker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_SELLER_FINANCE_REQUEST_CHECKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_SELLER_FINANCE_REQUEST_CHECKER),
    [NavigationMenuPermissions.ManageRejectedInstrumentsSellerFinanceRequest]:
      policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS) &&
      policyService.hasPolicies(PolicyType.UNDO_REJECTION_SELLER_FINANCE_REQUEST),
    [NavigationMenuPermissions.ApproveRejectInstrumentsPayerFinanceRequestMaker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_PAYER_FINANCE_REQUEST_MAKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_PAYER_FINANCE_REQUEST_MAKER),
    [NavigationMenuPermissions.ApproveRejectInstrumentsPayerFinanceRequestChecker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_PAYER_FINANCE_REQUEST_CHECKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_PAYER_FINANCE_REQUEST_CHECKER),
    [NavigationMenuPermissions.ManageRejectedInstrumentsPayerFinanceRequest]:
      policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS) &&
      policyService.hasPolicies(PolicyType.UNDO_REJECTION_PAYER_FINANCE_REQUEST),

    [NavigationMenuPermissions.InstrumentFunding]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS) &&
      (authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder),
    [NavigationMenuPermissions.FundInstruments]:
      policyService.hasPolicies(PolicyType.FUND_INSTRUMENTS) &&
      (authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder),

    [NavigationMenuPermissions.ViewIncomingPayments]: policyService.hasPolicies(PolicyType.VIEW_INCOMING_PAYMENTS),

    [NavigationMenuPermissions.ViewDisbursementsSection]:
      (authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder) &&
      (policyService.hasPolicies(PolicyType.VIEW_DISBURSEMENT_FILES) ||
        (policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
          policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS) &&
          policyService.hasPolicies(PolicyType.FUND_INSTRUMENTS))),

    [NavigationMenuPermissions.InstrumentDisbursements]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS) &&
      (authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder),
    [NavigationMenuPermissions.ViewDisbursementFiles]:
      policyService.hasPolicies(PolicyType.VIEW_DISBURSEMENT_FILES) &&
      (authFacadeService.userSignal()?.company_type === CompanyType.PortalAdmin ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder),

    [NavigationMenuPermissions.FinanceApproval]:
      policyService.hasPolicies(PolicyType.VIEW_INSTRUMENT_DETAILS) &&
      policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [NavigationMenuPermissions.ApproveRejectInstrumentsFunderApprovalMaker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_FUNDER_APPROVAL_MAKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_FUNDER_APPROVAL_MAKER),
    [NavigationMenuPermissions.ApproveRejectInstrumentsFunderApprovalChecker]:
      policyService.hasPolicies(PolicyType.CONFIRM_INSTRUMENTS_FUNDER_APPROVAL_CHECKER) &&
      policyService.hasPolicies(PolicyType.REJECT_INSTRUMENTS_FUNDER_APPROVAL_CHECKER),
    [NavigationMenuPermissions.ManageRejectedInstrumentsFunderApproval]:
      policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS) &&
      policyService.hasPolicies(PolicyType.UNDO_REJECTION_FUNDER_APPROVAL),
    [NavigationMenuPermissions.ManageFunderRejectedInstrumentsCustomer]:
      policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS) &&
      policyService.hasPolicies(PolicyType.UNDO_REJECTION_FUNDER_APPROVAL) &&
      (authFacadeService.userSignal()?.company_type === CompanyType.Customer ||
        authFacadeService.userSignal()?.company_type === CompanyType.Funder),

    [NavigationMenuPermissions.ViewOutstandingInstruments]: policyService.hasPolicies(PolicyType.VIEW_REPAYMENTS),
  };
};

export const NAVIGATION_MENU_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  NAVIGATION_MENU_IDENTITY_ACCESS_FACTORY
);

export const STORAGE_NAVIGATION_SIDEBAR_OPENED_KEY = 'navSidebarOpened';
export {
  NAVIGATION_SIDEBAR_MENU_SECTIONS,
  NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_CUSTOMERS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_ADMINS,
  NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_FUNDERS,
} from './navigation-sidebar-menu-items';

export { HEADER_NAVIGATION_MENU_ITEMS } from './header-navigation-menu-items';

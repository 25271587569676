import { HeaderNavigationMenuItem, HeaderNavigationMenuItemIdentifier } from '../types';

export const HEADER_NAVIGATION_MENU_ITEMS: HeaderNavigationMenuItem[] = [
  {
    identifier: HeaderNavigationMenuItemIdentifier.ProfileSettings,
    title: 'User Profile',
    route: '/user-profile',
    disabled: false,
    iconName: 'tuiIconUserLarge',
  },
  {
    identifier: HeaderNavigationMenuItemIdentifier.NotificationSettings,
    title: 'Notification Settings',
    route: '/notification-settings',
    disabled: false,
    iconName: 'fvIconNavNotification',
  },
];

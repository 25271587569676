export enum NavigationMenuPermissions {
  ViewDashboard = 'view_dashboard',
  ViewCompanies = 'view_companies',

  ViewSelfOnboarding = 'view_self_onboarding',

  ViewProducts = 'view_products',
  ViewProductGroups = 'view_product_groups',
  ViewProductStructures = 'view_product_structures',
  ViewFloatingRates = 'view_floating_rates',
  ViewFXRates = 'view_fx_rates',

  ViewProgrammesAndDeals = 'view_programmes_and_deals',
  ViewProgrammes = 'view_programmes',
  ViewDeals = 'view_deals',

  ViewUserManagement = 'view_user_management',
  ViewAuditTrail = 'view_audit_trail',
  ViewCompanyNotifications = 'view_company_notifications',

  ViewInstrumentUpload = 'view_instrument_upload',

  ViewDisbursements = 'view_disbursements',
  ViewDisbursementFiles = 'view_disbursement_files',

  ViewNotifications = 'view_notifications',

  InstrumentApproval = 'instrument_approval',
  ApproveRejectInstrumentsPayerApprovalMaker = 'approve_reject_instruments_payer_approval_maker',
  ApproveRejectInstrumentsPayerApprovalChecker = 'approve_reject_instruments_payer_approval_checker',
  ManageRejectedInstrumentsPayerApproval = 'manage_rejected_instruments_payer_approval',
  MarkAsPaidWithoutFinancing = 'mark_as_paid_without_financing',

  FinanceRequest = 'finance_request',
  ApproveRejectInstrumentsSellerFinanceRequestMaker = 'approve_reject_instruments_seller_finance_request_maker',
  ApproveRejectInstrumentsSellerFinanceRequestChecker = 'approve_reject_instruments_seller_finance_request_checker',
  ManageRejectedInstrumentsSellerFinanceRequest = 'manage_rejected_instruments_seller_finance_request',
  ApproveRejectInstrumentsPayerFinanceRequestMaker = 'approve_reject_instruments_payer_finance_request_maker',
  ApproveRejectInstrumentsPayerFinanceRequestChecker = 'approve_reject_instruments_payer_finance_request_checker',
  ManageRejectedInstrumentsPayerFinanceRequest = 'manage_rejected_instruments_payer_finance_request',

  InstrumentFunding = 'instrument_funding',
  FundInstruments = 'fund_instruments',

  ViewIncomingPayments = 'view_incoming_payments',

  ViewDisbursementsSection = 'view_disbursements_section',
  InstrumentDisbursements = 'instrument_disbursements',
  ViewRepayments = 'view_repayments',

  FinanceApproval = 'finance_approval',
  ApproveRejectInstrumentsFunderApprovalMaker = 'approve_reject_instruments_funder_approval_maker',
  ApproveRejectInstrumentsFunderApprovalChecker = 'approve_reject_instruments_funder_approval_checker',
  ManageRejectedInstrumentsFunderApproval = 'manage_rejected_instruments_funder_approval',
  ManageFunderRejectedInstrumentsCustomer = 'manage_funder_rejected_instruments_customer',
  ViewOutstandingInstruments = 'view_outstanding_instruments',
}
